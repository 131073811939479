import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
import { has } from "lodash";
import { ToastProgrammatic as Toast } from "buefy";
import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "karyaIlmiahList";
const modelPath = "/karyailmiah/";

class KaryaIlmiahList extends ListModelBase {
  constructor() {
    let filterMap = {
      stase:null,
      sub_departemen: null,
      verifikasi: null,
      lulus: null
    }
    super(modelName, modelPath, filterMap);
  }

  initObservables() {
    super.initObservables();
    this.observables.errorList = [];
    this.observables.kiBelumSet = {};
  }

  validateInline(idx) {
    let errorMap = this.observables.errorList[idx];
    if (this.observables.karyaIlmiahList[idx].nilai == null) {
      if (has(errorMap, "nilai")) {
        errorMap = "";
      }
    } else if (this.observables.karyaIlmiahList[idx].nilai_sikap == null) {
      if (has(errorMap, "nilai_sikap")) {
        errorMap = "";
      }
    }
  }

  getLoadData(data) {
    this.observables.kiBelumSet = {};
    let karyaIlmiahList = super.getLoadData(data);
    for (let ki of karyaIlmiahList) {
      let nilai = ki['nilai'];
      let nilai_sikap = ki['nilai_sikap'];
      ki['nilai'] = nilai? parseFloat(nilai):nilai;
      ki['nilai_sikap'] = nilai_sikap? parseFloat(nilai_sikap):nilai_sikap;
    }

    this.observables.errorList = [];
    let kiBelumSet = karyaIlmiahList.filter(
      d => d.hasil == 'Belum' || d.nilai == null
    );

    for (let ki of kiBelumSet) {
      this.observables.kiBelumSet[ki.id] = {hasil: ki.hasil, nilai: ki.nilai, nilai_sikap: ki.nilai_sikap};
    }
    return karyaIlmiahList;
  }

  getPayload() {
    let kiSet = this.observables.karyaIlmiahList.filter(
      d => has(this.observables.kiBelumSet, d.id) && 
      (d.nilai_sikap != null || d.nilai != null || d.hasil != 'Belum')
    );
    let data = kiSet.length > 0 ? {data_set: {}} : {data_set: null};
    for (let ki of kiSet) {
      let ver = ki.nilai != null && ki.hasil != "Belum" ? "Ya" : "Belum";
      ki.verifikasi = ver;
      data.data_set[ki.id] = {nilai_sikap: ki.nilai_sikap, hasil: ki.hasil, nilai:ki.nilai, verifikasi: ver};
    }
    return data;
  }

  updateErrorFields(respErrorList) {
    /* digunakan untuk load error dari response */
    this.observables.errorList = respErrorList;
  }

  update(onSaved) {
    let updateURL = `${APP_CONFIG.baseAPIURL}${modelPath}penilaian/`;
    const data = this.getPayload();
    if (data.data_set == null) return;

    axios.patch(updateURL, data)
    .then((response) => {
      Toast.open("Data berhasil disimpan.");
      if (onSaved) {
        onSaved(response.data);
      }
    })
    .catch((error) => {
      if (error.response.status === 400) {  // Perlu handle 403 juga
        Toast.open("Data gagal disimpan.");
        this.updateErrorFields(error.response.data);
      }
    });
  }
}

export default KaryaIlmiahList;
